import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function About() {
    useEffect(() => {
        AOS.init({ duration: 1000 })
    }, [])
    return (
        <section className="about-area page-section scroll-to-page" id="about">
            <div className="custom-container">
                <div className="about-content content-width">
                    <div className="section-header">
                        <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                            <i className="lar la-user"></i> About
                        </h4>
                        <h1 className="scroll-animation" data-aos='fade-up'>Seasoned product thinker.<br />Appetite for solving <span>complex business problems</span>.
                        </h1>
                    </div>
                    <p className="scroll-animation" data-aos='fade-up'>I thrive at the intersection of business strategy, cross-functional partnerships, and customer journeys.</p>
                    <p className="scroll-animation" data-aos='fade-up'>I can groom product strategies, redefine product experience, curate organizational design systems and define process for design@scale.</p>
                    <p className="scroll-animation" data-aos='fade-up'>I get people and it works the other way around too. I've played as Lone Wolf, Pack Member and Leader.</p>
                </div>
            </div>
        </section>
    )
}
