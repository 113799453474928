import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Lightbox from 'lightbox2';
import 'lightbox2/dist/css/lightbox.min.css';
// import 'lightbox2/dist/js/lightbox.js';

export default function Portfolio() {
    useEffect(() => {
        AOS.init({ duration: 1000 });
        Lightbox.option({
            resizeDuration: 200,
            fadeDuration: 600,
            imageFadeDuration: 600,
            wrapAround: true
        });
    }, []);
    return (
        <section className="portfolio-area page-section scroll-to-page" id="portfolio">
            <div className="custom-container">
                <div className="portfolio-content content-width">
                    <div className="section-header">
                        <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                            <i className="las la-grip-vertical"></i> portfolio
                        </h4>
                        <h1 className="scroll-animation">Featured <span>Projects</span></h1>
                    </div>

                    <div className="row portfolio-items">
                        {/* PORTFOLIO ITEM */}
                        <div className="col-md-12 scroll-animation" data-aos='fade-up'>
                            <div className="portfolio-item portfolio-full">
                                <div className="portfolio-item-inner">
                                    <img src="../assets/images/portfolio1.png" alt="Portfolio" />
                                    <ul className="portfolio-categories">
                                        <li>
                                            <a target="_blank" href="https://www.linkedin.com/pulse/learning-tech-petri-dish-designing-uncertain-knowns-koushik-venkatesh/?trackingId=KbMFMHKLRYG%2FNPTu4yIxkw%3D%3D"><i className="lab la-linkedin" />| Read </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 scroll-animation" data-aos='fade-right'>
                            <div className="portfolio-item portfolio-half">
                                <div className="portfolio-item-inner">
                                    <img src="../assets/images/portfolio2.png" alt="IntelliFlash-37-Dashboard" />
                                    <ul className="portfolio-categories">
                                        <li>
                                            <a target="_blank" href="https://www.behance.net/gallery/87000797/IntelliFlash-37-Dashboard"><i className="lab la-behance" />| Read </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 scroll-animation" data-aos='fade-left'>
                            <div className="portfolio-item portfolio-half">
                                <div className="portfolio-item-inner">
                                    <img src="../assets/images/portfolio3.png" alt="Portfolio" />
                                    <ul className="portfolio-categories">
                                        <li>
                                            <a target="_blank" href="https://medium.com/@koushik.venkatesh/tegile-design-system-726041557584"><i className="lab la-medium" />| Read </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <h2 className="scroll-animation">Other <span>Projects</span></h2>
                        <div className="col-md-6 scroll-animation" data-aos='fade-right'>
                            <div className="portfolio-item portfolio-half">
                                <div className="portfolio-item-inner">
                                    <img src="../assets/images/portfolio4.png" alt="Bluevine Take-Home Exercise" />
                                    <ul className="portfolio-categories">
                                        <li>
                                            <a target="_blank" href="https://www.behance.net/gallery/85633675/Credit-Card-Monitoring"><i className="lab la-behance" />| Read </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 scroll-animation" data-aos='fade-left'>
                            <div className="portfolio-item portfolio-half">
                                <div className="portfolio-item-inner">
                                    <img src="../assets/images/portfolio5.png" alt="Aware.ai Take-Home Exercise" />
                                    <ul className="portfolio-categories">
                                        <li>
                                            <a target="_blank" href="https://www.behance.net/gallery/85659245/Aware-Command-Center"><i className="lab la-behance" />| Read </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 scroll-animation" data-aos='fade-up'>
                            <div className="portfolio-item portfolio-full">
                                <div className="portfolio-item-inner">
                                    <img src="../assets/images/portfolio6.png" alt="Portfolio" />
                                    <ul className="portfolio-categories">
                                        <li>
                                            <a target="_blank" href="https://www.behance.net/gallery/85356991/NextBlock"><i className="lab la-behance" />| Read </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </section>
    )
}
