import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function Skills() {
    useEffect(() => {
        AOS.init({ duration: 1000 })
    }, [])
    return (
        <section className="skills-area page-section scroll-to-page" id="skills">
            <div className="custom-container">
                <div className="skills-content content-width">
                    <div className="section-header">
                        <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                            <i className="las la-shapes"></i> Skills
                        </h4>
                        <h1 className="scroll-animation" data-aos='fade-up'>My <span>Builders</span> box</h1>
                    </div>
                    <div className="row skills text-center">
                        <div className="col-md-3 scroll-animation" data-aos='fade-right'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/images/react.png" alt="Photoshop" className='skills-asset' />
                                    <h1 className="percent">React</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 scroll-animation" data-aos='fade-right'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/images/expo.png" alt="EXPO" className='skills-asset' />
                                    <h1 className="percent">EXPO</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 scroll-animation" data-aos='fade-right'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/images/aws.png" alt="AWS" className='skills-asset' />
                                    <h1 className="percent">AWS</h1>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 scroll-animation" data-aos='fade-right'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/images/sst.png" alt="SST" className='skills-asset' />
                                    <h1 className="percent">SST</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 scroll-animation" data-aos='fade-right'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/images/figma.png" alt="Figma" className='skills-asset' />
                                    <h1 className="percent">Figma</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 scroll-animation" data-aos='fade-right'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/images/adobe_xd.png" alt="XD" className='skills-asset' />
                                    <h1 className="percent">XD</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 scroll-animation" data-aos='fade-right'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/images/bootstrap.png" alt="Bootstrap" className='skills-asset' />
                                    <h1 className="percent">Bootstrap</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 scroll-animation" data-aos='fade-right'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/images/tailwind.png" alt="Tailwind" className='skills-asset' />
                                    <h1 className="percent">Tailwind</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 scroll-animation" data-aos='fade-right'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/images/adobe_ai.png" alt="Illustrator" className='skills-asset' />
                                    <h1 className="percent">Photoshop</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 scroll-animation" data-aos='fade-right'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/images/adobe_ps.png" alt="Photoshop" className='skills-asset' />
                                    <h1 className="percent">Photoshop</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 scroll-animation" data-aos='fade-right'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/images/adobe_ae.png" alt="After Effects" className='skills-asset' />
                                    <h1 className="percent">After Effects</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 scroll-animation" data-aos='fade-right'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/images/iot.png" alt="IOT" className='skills-asset' />
                                    <h1 className="percent">IOT</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 scroll-animation" data-aos='fade-right'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/images/aiml.png" alt="AI/ML" className='skills-asset' />
                                    <h1 className="percent">AI/ML</h1>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </section>
    )
}
