import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
// Linked Apps
import App from './App'; // Enfresko.com
import reportWebVitals from './reportWebVitals';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);


/*

{
    path: "/omg/:name",
    element: <Omg />,
  },
  {
    path: "/rftms/:name",
    element: <Ramen />,
  },
  {
    path: "/pc/tee/:name",
    element: <PrintConfigurator />,
  },

*/
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
