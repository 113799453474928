import React from 'react'

export default function Lsb() {
    return (
        <div className="left-sidebar">
            <div className="sidebar-header d-flex align-items-center justify-content-between">
                <img src="./assets/images/logo.png" alt="Logo" className='EnfreskoLogo' />
                <span className='designation'>Enfresko Designs</span>
            </div>
            <img className="me" src="./assets/images/me.jpg" alt="Me" />
            <h1 className="Name">Koushik Venkatesh</h1>
            <h2 className="designation">Product Design Technologist</h2>
            <h4 className="address">San Francisco Bay, CA</h4>
            <p className="copyright">&copy; 2023 Enfresko Designs, All Rights Reserved</p>
            <ul className="social-profile d-flex align-items-center flex-wrap justify-content-center">
                <li>
                    <a href="https://www.linkedin.com/in/koushikv/"><i className="lab la-linkedin"></i></a>
                </li>
                <li>
                    <a href="https://medium.com/@koushik.venkatesh"><i className="lab la-medium"></i></a>
                </li>
            </ul>

        </div>
    )
}


/*
<a href="#contact" className="theme-btn scroll-to">
                <i className="las la-envelope"></i> Contact Me
            </a>

*/