import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function Herosec() {
    useEffect(() => {
        AOS.init({ duration: 1000 })
    }, [])
    return (
        <section className="hero-section page-section scroll-to-page" id="home">
            <div className="custom-container">
                <div className="hero-content content-width">
                    <div className="section-header">
                        <h4 className="subtitle scroll-animation" data-animation="fade_from_bottom" data-aos='fade-up'>
                            <i className="las la-home"></i> Home
                        </h4>
                        <h1 className="scroll-animation" data-aos='fade-up'>I hunt for <span>problems</span>, design experiences and build <span>solutions</span>.</h1>
                    </div>

                    <p className="scroll-animation" data-aos='fade-up'>I enjoy learning the problem space, the people and systems in the mix. Building digital experiences around these complex problems is why I lace up my boots for work!</p>
                    <div className="facts d-flex">
                        <div className="left scroll-animation" data-aos='fade-right'>
                            <p>Years of <br />Experience</p><h1>18+</h1>
                        </div>
                        <div className="right scroll-animation" data-aos='fade-left'>
                            <p>Day to day</p>
                            <h3>Experiment, prototype & unlock value</h3>
                        </div>
                        <div className="right scroll-animation" data-aos='fade-left'>
                            <p>Current Org Fuction</p><h3>Sales led  <br />Engineering</h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
