import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function Specializations() {
    useEffect(() => {
        AOS.init({ duration: 1000 })
    }, [])
    return (

        <section className="services-area page-section scroll-to-page" id="specializations">
            <div className="custom-container">
                <div className="services-content content-width">
                    <div className="section-header">
                        <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                            <i className="las la-stream"></i> Crafts
                        </h4>
                        <h1 className="scroll-animation" data-aos='fade-up'>My <span>Specializations</span></h1>
                    </div>
                    <div className="row portfolio-items">
                        <div className="col-md-6 scroll-animation" data-aos='fade-up'>
                            <div className="portfolio-item portfolio-half">
                                <div className="portfolio-item-inner">
                                    <img src="../assets/images/specializations/Specializations1.png" alt="Customer Journey" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 scroll-animation" data-aos='fade-left'>
                            <div className="portfolio-item portfolio-half">
                                <div className="portfolio-item-inner">
                                    <img src="../assets/images/specializations/Specializations2.png" alt="Product Strategy" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 scroll-animation" data-aos='fade-down'>
                            <div className="portfolio-item portfolio-half">
                                <div className="portfolio-item-inner">
                                    <img src="../assets/images/specializations/Specializations3.png" alt="Interaction and Motion Design" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 scroll-animation" data-aos='fade-right'>
                            <div className="portfolio-item portfolio-half">
                                <div className="portfolio-item-inner">
                                    <img src="../assets/images/specializations/Specializations4.png" alt="Designn Operations" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 scroll-animation" data-aos='fade-up'>
                            <div className="portfolio-item portfolio-half">
                                <div className="portfolio-item-inner">
                                    <img src="../assets/images/specializations/Specializations5.png" alt="Brand & Identity" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 scroll-animation" data-aos='fade-left'>
                            <div className="portfolio-item portfolio-half">
                                <div className="portfolio-item-inner">
                                    <img src="../assets/images/specializations/Specializations6.png" alt="Digital Publications" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 scroll-animation" data-aos='fade-left'>
                            <div className="portfolio-item portfolio-half">
                                <div className="portfolio-item-inner">
                                    <img src="../assets/images/specializations/Specializations7.png" alt="Rapid Prototyping" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 scroll-animation" data-aos='fade-left'>
                            <div className="portfolio-item portfolio-half">
                                <div className="portfolio-item-inner">
                                    <img src="../assets/images/specializations/Specializations8.png" alt="Story Telling" />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}
