import React, { useEffect, useState } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

import { API } from "aws-amplify";
//import { createContactFormSubmissions } from '../../graphql/mutations';

import Form from "@cloudscape-design/components/form";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Container from "@cloudscape-design/components/container";
import Header from "@cloudscape-design/components/header";
import FormField from "@cloudscape-design/components/form-field";
import Input from "@cloudscape-design/components/input";


export default function Contact() {

    const debug = true;

    useEffect(() => {
        AOS.init({ duration: 1300 })
    }, [])
    const [subject, setSubject] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    async function handleFormSubmit() {

        /* 
        const newContactFormSubmissions = await API.graphql({
            query: createContactFormSubmissions,
            variables: {
                input: {
                    "name": name,
                    "email": email,
                    "subject": subject,
                    "message": message
                }
            }
        });
        */

    }




    return (
        <section className="contact-area page-section scroll-content" id="contact">
            <div className="custom-container">
                <div className="contact-content content-width">
                    <div className="section-header">
                        <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                            <i className="las la-dollar-sign"></i> contact
                        </h4>
                        <h1 className="scroll-animation" data-aos='fade-up'>Let's do <span>Honest </span> Work Together!</h1>
                    </div>
                    <h3 className="scroll-animation" data-aos='fade-up'>Reach out for employment opportunities, volunteering time or consultation.<br /><br></br>Random chats on "making equitable tech" welcome!</h3>
                    <h3 className="scroll-animation" data-aos='fade-up'><br></br>Message me on <a href="https://www.linkedin.com/in/koushikv/"><i className="lab la-linkedin"></i></a> ✌️</h3>
                    <p id="required-msg">* Marked fields are required to fill.</p>

                    {!debug &&
                        <form className="contact-form scroll-animation" data-aos='fade-up' onSubmit={(e) => {
                            e.preventDefault();
                            console.log("Submit FORM :", name, email, subject, message);
                            handleFormSubmit();
                        }}>
                            <div className="alert alert-success messenger-box-contact__msg" style={{ display: 'none' }} role="alert">
                                Your message was sent successfully.
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="input-group">
                                        <label htmlFor="full-name">Full Name <sup>*</sup></label>
                                        <input type="text" name="full-name" id="full-name" placeholder="Your Full Name" value={name} onChange={({ target }) => {
                                            //console.log("Name => ", target.value);
                                            setName(target.value)
                                        }} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input-group">
                                        <label htmlFor="email">Email <sup>*</sup></label>
                                        <input type="email" name="email" id="email" placeholder="Your email adress" value={email} onChange={({ target }) => {
                                            //console.log("Email => ", target.value);
                                            setEmail(target.value)
                                        }} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input-group">
                                        <label htmlFor="subject">subject <sup>*</sup></label>
                                        <select name="subject" value={subject} id="subject" onChange={({ target }) => {
                                            //console.log("Subject => ", target.value);
                                            setSubject(target.value)
                                        }}>
                                            <option value="">Select a subject</option>
                                            <option value="Employment Opportunity">Employment Opportunity</option>
                                            <option value="Consultation">Consultation</option>
                                            <option value="Something else">Something else</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="input-group">
                                        <label htmlFor="message">message</label>
                                        <textarea name="message" id="message" placeholder="Wrire your message here ..." value={message} onChange={({ target }) => {
                                            //console.log("Message => ", target.value);
                                            setMessage(target.value)
                                        }}></textarea>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="input-group submit-btn-wrap">
                                        <button className="theme-btn" name="submit" type="submit" id="submit-form">send message</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    }
                </div>
            </div>
        </section>
    )
}
